.background {
  background: #14171f;
  height: 100%;
  width: 100%;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: overlay;
  overflow-x: hidden;
}

body {
  background: #1f2937;
  height: 100%;
  width: 100%;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: overlay;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 6px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.background-color {
  background: rgba(32, 27, 44, 0.9);
}

.question-selector-style {
  color: white;
}

.not-current-question-opacity {
  opacity: 0.7;
  /* mask-image: linear-gradient(270deg, #612293 0.27%, #2B0945 100%);
    -webkit-mask-image: linear-gradient(270deg, #612293 0.27%, #2B0945 100%); */
}

.question-selection-hover:hover {
  cursor: pointer;
}

.question-selection-hover img {
  margin-bottom: -1px;
  opacity: 0.9;
  /* width: 100%; */
}

.question-selection-hover {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.question-selection-text {
  font-family: Plus Jakarta Sans;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 2px;
  text-align: left;
  color: #000000;
  margin-left: 0%;
  width: 28%;
  text-align: center;
  /* margin-bottom: 5px;
    margin-left: 5px;
    padding: 5px 5px 5px 5px; */
}

.viewer-card-body .timer {
  color: #fff;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  flex-wrap: nowrap;
  font-weight: 100;
  letter-spacing: 0.22rem;
}

.viewer-card-body .question-title {
  color: #fff;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  margin-left: 0.3rem;
  margin-left: 32px;
}

.viewer-card-body .startbutton {
  padding: 8px 0px 10px 0px;
  font-size: 12px;
  color: #ffffff;
  background: #d2193a;
  border: none;
}

.viewer-card-body .pretend-btn {
  width: 80%;
  font-size: 8px;
  font-family: Plus Jakarta Sans;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0px 10px 0px;
  text-align: center;
  margin: 20px auto 40px auto;
}

.add-answer-button {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 55px;
  padding: 16px;
  color: #ffffff;
  text-transform: uppercase;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  /* thanks landon */
  margin: 50px auto 0 auto;
  border: none;
  /* margin: 15px 20px 20px 40px; */
}

.remove-padding {
  padding-left: 0px !important;
  display: table-cell;
  vertical-align: middle;
}

.type3 {
  height: 16px;
  width: 16px !important;
  background: #fce552;
  border: 1px solid rgba(255, 255, 255, 0);
  margin-left: 10px;
  /* margin-top: 13px !important; */
  padding: unset !important;
  margin-top: 2px;
}

.type2 {
  height: 16px;
  width: 16px !important;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.26);
  margin-left: 10px;
  /* margin-top: 13px !important; */
  padding: unset !important;
  margin-top: 3px;
}

.circleBase {
  border-radius: 100%;
}

.type6 {
  height: 7px;
  width: 7px;
  background-color: #ffffff;
  margin-top: 3px;
  margin-left: 2.6px;
  /* border: 1px solid rgb(255, 255, 255); */
  /* margin-left: 100px; */
  /* margin-top: 2.5% !important; */
}

.circleBase1 {
  border-radius: 100%;
  background-color: #ffffff;

  /* z-index: 1; */
}

.choice-answer {
  color: #ffffff;
  font-size: 16px;
}

.answer-choice-txt {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
}

.submitbtn {
  width: 112%;
}

.submitbtnrowalign {
  margin-top: auto !important;
}

.submitbtn1 {
  background-color: transparent;
  border: none;
  /* margin-top: -1px; */
  /* margin-left: -1px; */
  /* margin-left: -1.5% !important; */
  /* margin-bottom: -50px; */
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.grayscale-img:hover {
  filter: grayscale(20%);
}

.selected-answer-question {
  color: #ffffff !important;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  margin-top: 8px;
  margin-left: 4px;
}

.selected-answer-txt {
  color: #a19e9e !important;
  font-family: Plus Jakarta Sans;
  text-align: center;
  font-size: 16px;
}

.leaderboard-position-number {
  color: #ffffff !important;
  font-family: Plus Jakarta Sans;
  text-align: left;
  font-size: 10px;
  margin-top: 6px !important;
  padding-right: 0px !important;
}

.leaderboard-position {
  position: absolute;
  bottom: 0px;
}

.leaderboard-position-number p {
  display: inline;
  margin-top: 5px !important;
}

.leaderboard-position-image {
  margin-bottom: 3px;
  margin-right: 8px;
}

.leaderboard-position-1 {
  color: #ffde46 !important;
}

.leaderboard-position-2 {
  color: #dddddd !important;
}

.leaderboard-position-3 {
  color: #de9b4d !important;
}

.leaderboard-position-n {
  margin-left: 21px;
}

.leaderboard-btn {
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
}

.leaderboard-img {
  text-align: left;
  width: 100%;
}

.answer-button {
  color: #ffffff;
  text-align: left;
  overflow: hidden;
  cursor: pointer;
  height: 35px;
}

.answer-block {
  max-height: 400px;
  /* overflow-y: scroll; */
  background-color: #191c26;
}

.p-style-viewer-submit {
  position: absolute;
  left: 40%;
  top: 16px;
  display: inline;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  letter-spacing: 0.22rem;
  color: white;
  /* text-align: center; */
}

.selected-answer-name {
  color: #ffffff !important;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  margin-top: 9px;
  margin-left: 4px;
}

.leaderboard-img-M {
  width: 103%;
}

.qustion-text-div {
  text-align: center;
}

.question-text-msg {
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  color: #ffffff;
}

.question-anschoice-div {
  text-align: center;
}

.question-ans-choice-msg {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  color: #fce552;
}

.confirm-btn-div {
  text-align: center;
  cursor: pointer;
}

.confirm-btn {
  cursor: pointer;
}

.confirm-btn:hover {
  cursor: pointer;
}

.go-back-txt-div {
  text-align: center;
}

.go-back-txt-msg {
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.22em;
  cursor: pointer;
}

.percent-correct-question-txt {
  color: #ffffff !important;
  font-family: Plus Jakarta Sans;
  text-align: left;
  font-size: 16px;
  margin-top: 5%;
  margin-bottom: 40px;
}

.percent-correct-number-txt {
  font-family: Plus Jakarta Sans;
  opacity: 50%;
  color: #ffffff;
  font-size: 16px;
}

.percent-correct-number-txt-correct {
  font-family: Plus Jakarta Sans;
  color: #a237f5;
  font-size: 16px;
}

.percent-correct-answers-txt-correct {
  font-family: Plus Jakarta Sans;
  color: gold;
  font-size: 16px;
}

.percent-correct-answers-txt {
  font-family: Plus Jakarta Sans;
  color: #ffffff;
  font-size: 16px;
}

.percent-correct-percentage-correct {
  color: gold;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  margin-right: -25px;
}

.percent-correct-percentage {
  font-family: Plus Jakarta Sans;
  color: #ffffff;
  font-size: 16px;
  margin-right: -25px;
}

.percent-bar-removepadding {
  padding-right: 0px !important;
}

.bottom-bar {
  margin-bottom: 16px;
  margin-left: auto;
}

.progress-bar {
  background-color: rgba(255, 255, 255, 0.2) !important;
  height: 4px;
  border-radius: 0px;
}

.total-votes-viewer {
  text-align: right;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px;
  color: #ffffff;
}

.percent-correct-card-body {
  width: 350px;
  margin-left: 25px;
  margin-top: 50px;
}

.leaderboard-card-body {
  background: rgba(32, 27, 44, 0.9) !important;
  transition: all 0.5s ease;
  height: 430px;
  width: 400px;
}

.leaderboard-name-text-centered {
  color: #ffffff !important;
  font-family: plus-jakarta-sans;
  text-align: left;
  font-size: 10px;
  margin-left: -20px;
  letter-spacing: 3px;
  margin-bottom: unset !important;
  text-align: center;
}

.leaderboard-name-text {
  color: #ffffff !important;
  font-family: Plus Jakarta Sans;
  text-align: left;
  font-size: 12px;
  margin-left: -50px;
  letter-spacing: 3px;
  margin-bottom: unset !important;
}

.symbol-fontsize {
  font-size: 11px;
}

.leaderboard-numberofpoints {
  color: #a1a8bc !important;
  font-family: Plus Jakarta Sans;
  text-align: left;
  font-size: 11px;
  margin-left: -50px;
}

.leaderboard-info-tab {
  padding: 10px 0px 10px 0px;
  background-color: #201b2c !important;
}

.tab-menu-left-wrapper {
  text-align: left;
}

.leaderboard-time-left {
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: 16px;
  margin-bottom: 0 !important;
}

.leaderboard-timer-countdown {
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: 16px;
  /* margin-top: 6px; */
  margin-bottom: 0 !important;
}

.tab-menu-right-wrapper {
  text-align: right;
}

.leaderboard-menu-title {
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 16px;
  margin-bottom: 0 !important;
}

.leaderboard-menu-timeframe {
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 16px;
  /* margin-top: 6px; */
  margin-bottom: 0 !important;
}

.leaderboard-tab-col {
  background: rgba(32, 27, 44, 0.9) !important;
}

.leaderboard-tab-active {
  cursor: pointer;

  background: #201b2c;
}

.leaderboard-tab {
  cursor: pointer;
}

.leaderboard-tab-active {
  cursor: pointer;

  background: #201b2c;
}

.leaderboard-tab-active img {
  width: 25px;
  opacity: 100%;
}

.leaderboard-tab-img {
  width: 25px;
  margin: 8px auto;
}

.leaderboard-tab img {
  width: 25px;
  opacity: 60%;

  /* margin: 8px 28px; */
}

.leaderboard-info-tab {
  padding: 10px 0px 10px 0px;
  background-color: #201b2c !important;
}

.leaderboard-tab-col {
  background: rgba(32, 27, 44, 0.9) !important;
}

.leaderprofileimage {
  width: 30px;
}

.percent-correct-row {
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.margin-top-15 {
  margin-top: 15%;
}

.text-center {
  text-align: center;
}

.on-hover {
  cursor: pointer;
}

.viewerbegin-confirm-btn {
  /* margin-top: 20px; */
  width: 55%;
}

.image-container {
  position: relative;
  display: inline-block;
  /* or use block to take full width */
  text-align: center;
  /* Center the content horizontally */
}

.viewerbegin-confirm-btn {
  width: 75%;
}

.begin-text {
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
}

.margin-top-5 {
  margin-top: 5%;
}

.viewerbegin-title {
  color: #fce552;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Plus Jakarta Sans;
  text-shadow: 0px 0px 10px #fce552;
}

.loader {
  margin-top: 30%;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #a136f3;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-logo {
}

.loading-text {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  letter-spacing: 0.1em;
}

.loading-text-p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  letter-spacing: 1px;
}

.noaccess-title {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  weight: 600;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.noaccess-p {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  letter-spacing: 1px;
  color: #ffffff;
}

.login-margin-noaccess {
  margin-top: 25%;
}

.streamerlinkbtn {
  width: 50%;
  height: 50px;
  border-radius: 26px;
  font-family: Plus Jakarta Sans;
  text-transform: uppercase;
}

.topbar {
  position: absolute;
  top: -4px;
  width: 100%;
}

.access-denied-background {
  background: linear-gradient(
    to bottom,
    #14171f,
    #1b1a20 30%,
    #241e23 50%,
    #3a2727 70%,
    #7d4435
  );
  height: 100%;
  /* Ensure it takes the full height of the viewport */
  width: 100%;
  /* Ensure it takes the full width of the viewport */
  display: flex;
  flex-direction: column;
  /* Align children vertically */
  justify-content: center;
  /* Center children vertically */
  align-items: center;
  /* Center children horizontally */
}

.OSbtn {
  width: 70%;
  cursor: pointer;
  position: relative;
}

.OStext {
  color: #000000;
  position: absolute;
  left: 115px;
  bottom: 160px;
  font-weight: 610;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
}

.ADsquares {
  position: absolute;
  bottom: 0px;
  width: 100%;
  pointer-events: none;
}

.loading-background {
  background: linear-gradient(
    to bottom,
    #14171f,
    #1e2121 30%,
    #262723 50%,
    #424029 70%,
    #837937
  );
  height: 100%;
  /* Ensure it takes the full height of the viewport */
  width: 100%;
  /* Ensure it takes the full width of the viewport */
  display: flex;
  flex-direction: column;
  /* Align children vertically */
  justify-content: center;
  /* Center children vertically */
  align-items: center;
}

.loader-margin {
  margin-top: 20%;
  left: 30px !important;
}

.LS {
  /* position: absolute; */
  /* bottom: 0px; */
  width: 100%;
  pointer-events: none;
  opacity: 0.1;
}

.border-q {
  height: 60px;
}

.nav-logo {
  padding-left: 1rem;
  width: 100%;
}

.zero-padding {
  padding: 0px !important;
  background-color: #14171f !important;
}

.accordion-button {
  padding: 0px 15px !important;
  display: block;
}

.accordion {
  --bs-accordion-bg: #14171f !important;
  --bs-accordion-border-color: none;
  --bs-accordion-border-width: none;
  --bs-accordion-border-radius: none;
}

.text-c {
  color: #ffffff;
  font-family: 'Plus Jakarta Sans';
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #14171f !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.gamebox {
  width: 24px;
  height: 25px;
  background: #2b2e35;
  border-radius: 10%;
  color: white;
  text-align: center;
  font-family: Plus Jakarta Sans;
  padding: 0px 5px;
}

.flex-class {
  display: flex;
}

.game-text {
  font-family: Plus Jakarta Sans;
  color: #6b6e73;
  padding-left: 10px;
}

.money-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.money-symbol {
}

.confirm-box {
  background: #14171f;
  /* position: absolute;
  bottom: 40px;
  width: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.your-answer-txt {
  color: #ffffff;
}

.submitbtn1 {
  width: 100%;
}

.selected-ans-bg {
  background: #191c26;
  border-top: 1px solid #2b2e35;
  border-bottom: 1px solid #2b2e35;
  padding: 0px 15px;
}

.see-all-btn {
  color: #6e7076 !important;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  background-color: #30323b;
  margin-right: 16px;
  margin-top: 10px;
  width: 78px;
  padding-left: 2px;
  border-radius: 5px;
  height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.ans-check {
  width: 20px;
  height: 20px;
  margin-top: 12px;
  margin-left: 12px;
}

.dollar {
  width: 18px;
  height: 18px;
  margin-top: 12px;
  margin-left: -16px;
}

.twoX {
  margin-top: 12px;
}

.bg-color {
  background-color: #191c26 !important;
}

.height-setter {
  height: 45px;
}

.empty-ppl {
  margin-left: 13px;
}

.gold-ans {
  margin-right: -13px;
  margin-top: -8px;
}

.empty-ans {
  margin-left: 13px;
}

.percent-correct-answers-txt-wrong {
  margin-top: 4px;
}

.gold-ppl {
  margin-top: -2px;
}

.game-txt {
  color: #fce552;
  margin-left: 0.5rem;
  text-shadow: 0px 0px 10px #fce552;
  font-weight: 600;
}

.question-txt {
  font-weight: 600;
  margin-left: 20px;
}

.time-row {
  background-color: #191c26;
  border-bottom: 1px solid #2b2e35;
  margin-bottom: 1rem;
}

.viewer-card-body {
  background-color: #191c26;
}

.non-active-m {
  margin-left: 0.5rem;
  font-weight: 600;
}

.answer-drop-span {
  background-color: #2b2e35;
  padding: 5px;
  border-radius: 5px;
  color: #fce552;
  text-shadow: 0px 0px 10px #fce552;
}

.active-drop-span {
  background-color: #2b2e35;
  padding: 5px;
  border-radius: 5px;
  color: #ffffff;
}

.margintop-25 {
  margin-top: 30%;
}

.selected-answer-name-no-answer {
  color: #808287 !important;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  margin-top: 9px;
  margin-left: 4px;
}

.light-bg {
  background-color: #191c26;
}

.no-answer-text {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 1rem;
}

.App {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100vh;
}

.left-side {
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-side {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 20px;
}

.top-right {
  background-color: #e0e0e0;
  padding: 20px;
}

.bottom-right {
  background-color: #d0d0d0;
  padding: 20px;
}

.placeholder-box {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #999;
}

.placeholder-box p {
  font-size: 18px;
  color: #666;
}

.mode-image {
}

.live-informations {
  display: none !important;
}
